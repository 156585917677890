import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [manufacturers, setManufacturers] = useState([]);
  const [filteredManufacturers, setFilteredManufacturers] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedRating, setSelectedRating] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchManufacturers = async () => {
      const response = await axios.get('/api/data');
      setManufacturers(response.data);
      setFilteredManufacturers(response.data); // Initialize with all manufacturers
    };

    fetchManufacturers();
  }, []);

  useEffect(() => {
    filterManufacturers(selectedLocation, selectedRating, searchTerm);
  }, [selectedLocation, selectedRating, searchTerm, manufacturers]);

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleRatingChange = (event) => {
    setSelectedRating(Number(event.target.value));
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filterManufacturers = (location, rating, search) => {
    const filtered = manufacturers.filter(manufacturer => {
      const locationMatch = location ? manufacturer.Location === location : true;
      const ratingMatch = manufacturer.Rating >= rating;
      const searchMatch =
        manufacturer['Company Name'].toLowerCase().includes(search.toLowerCase()) ||
        manufacturer['Categories of Manufacturing'].toLowerCase().includes(search.toLowerCase()) ||
        manufacturer.Location.toLowerCase().includes(search.toLowerCase());

      return locationMatch && ratingMatch && searchMatch;
    });
    setFilteredManufacturers(filtered);
  };

  const uniqueLocations = [...new Set(manufacturers.map(m => m.Location))];

  return (
    <div className="app-container">
      <header className="header">
        <div className="company-name">YourEaze</div>
        <input
          type="text"
          className="search-bar"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="contact-button">Contact Us</button>
      </header>
      <div className="content-container">
        <aside className="filter-sidebar">
          <h2>Filters</h2>
          <label htmlFor="location">Location:</label>
          <select
            id="location"
            className="filter-dropdown"
            value={selectedLocation}
            onChange={handleLocationChange}
          >
            <option value="">All Locations</option>
            {uniqueLocations.map((location, index) => (
              <option key={index} value={location}>{location}</option>
            ))}
          </select>

          <label htmlFor="rating">Rating:</label>
          <input
            id="rating"
            type="range"
            min="0"
            max="5"
            step="0.1"
            value={selectedRating}
            onChange={handleRatingChange}
            className="rating-slider"
          />
          <div className="rating-display">Selected Rating: {selectedRating}</div>
        </aside>
        <main className="tiles-container">
          {filteredManufacturers.map((manufacturer, index) => (
            <div key={index} className="tile">
              <h2>{manufacturer['Company Name']}</h2>
              <p>
              <strong>Categories:</strong> {manufacturer['Categories of Manufacturing'].split(',').map((category, idx) => (
              <span key={idx} className="category-item">{category.trim()}</span>))}
              </p>
              <p><strong>Location:</strong> {manufacturer.Location}</p>
              <p><strong>Rating:</strong> {manufacturer.Rating}</p>
            </div>
          ))}
        </main>
      </div>
    </div>
  );
}

export default App;
